<template>
	<form @submit.prevent="login">
		<div class="d-flex flex-wrap align-items-center w-100 justify-content-center mb-5">
			<div class="col-md-2 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start pl-md-0">
				<z-back-button @click.native="back" />
			</div>
			<div class="col-12 col-md-8">
				<h3 class="mb-0">
					Prihlásenie
				</h3>
			</div>
			<div class="col-md-2"></div>
		</div>

		<w-input v-model="$v.form.login" label="Email" />
		<w-input v-model="$v.form.password" label="Password" type="password" />

		<div class="d-flex justify-content-between">
			<router-link class="font-weight-bold text-dark" :to="{ name: 'ForgotPassword' }">
				Zabudli ste heslo?
			</router-link>
			<span
				>Nemáte ešte vytvorené konto?
				<router-link class="font-weight-bold text-dark" :to="{ name: 'Registration' }">
					Registrujte sa.
				</router-link>
			</span>
		</div>

		<b-button pill variant="success" class="z-submit-btn mt-3 float-right" type="submit">Prihlásiť sa</b-button>
	</form>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'

export default {
	data() {
		return {
			form: {
				login: localStorage.getItem('hemisfera_preregEmail'),
				password: ''
			}
		}
	},

	mounted() {
		this._trackDataLayerEvent()
	},

	validations() {
		return {
			form: {
				login: { required, email },
				password: { minLength: minLength(3) }
			}
		}
	},

	computed: {
		...mapGetters('wAuth', ['isLoggedIn']),
		...mapState('order', ['orderRegistrations'])
	},

	methods: {
		async login() {
			this.$wToast.clear_loading()
			if (this.$v.$invalid) {
				this.$v.$touch()
				return
			}

			try {
				await this.$store.dispatch('wAuth/login', { login: this.form.login, password: this.form.password })
				if (this.isLoggedIn) {
					await this.$store.dispatch('order/attachOrderToUser')

					localStorage.removeItem('hemisfera_preregEmail')
					this.$router.push({ name: 'StudentsInformation' })
				}
			} catch (error) {
				this.$wToast.error(error)
			}
		},

		back() {
			this.$router.go(-1)
		},
		DEV_prefill() {
			this.form = {
				login: 'testing@wezeo.com',
				password: 'testing123'
			}
		},

		_trackDataLayerEvent() {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'checkout_progress',
				pageTitle: 'Prihlásenie',
				product: this.orderRegistrations ? this.orderRegistrations[0].product_period.product : ''
			})
		}
	}
}
</script>
